import Compressor from "compressorjs";

export const useInputImageRenderer = (inputEl, callback) => {
  const inputImageRenderer = () => {
    const file = inputEl.files[0];
    new Compressor(file, {
      width: 200,
      height: 200,
      quality: 0.2,
      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        var reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onload = () => {
          callback(reader.result);
        };
      },
      error(err) {},
    });
  };
  return {
    inputImageRenderer,
  };
};

export const _ = null;
