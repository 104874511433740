<template>
  <div>
    <account-settings></account-settings>
  </div>
</template>

<script>
import AccountSettings from "./AccountSetting.vue";
export default {
  components: {
    AccountSettings,
  },
  mounted() {},
};
</script>

<style></style>
